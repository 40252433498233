// import 'babel-polyfill'
import Cookie from 'js-cookie'
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';

Vue.use(ElementUI);
import store from './store'
// import Base64 from 'js-base64'
// Vue.use(Base64)

import htmlToPdf from '@/assets/js/pdf.js';//路径根据自己的js文件来引入

Vue.use(htmlToPdf);

import './fonts/fonts.css'

// 滚动数据
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
// 百度的统计代码

// var _hmt = _hmt || [];
// (function () {
//   var hm = document.createElement("script");
//   hm.src = "https://hm.baidu.com/hm.js?7b64961c365f4085633e253765a666fb";
//   var s = document.getElementsByTagName("script")[0];
//   s.parentNode.insertBefore(hm, s);
// })();


// import Es6Promise from 'es6-promise'
// 全局样式
import '@/assets/styles/index.less'



// 引入百度地图
import BaiduMap from 'vue-baidu-map'
import router from './router'



// 引入rem
// import 'lib-flexible'

// 使用cookie
Vue.prototype.$Cookie = Cookie

// 注册一个全局的vue title指令
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = binding.value
    // console.log(binding)
  }
})
// Vue.directive('title', {
//   inserted: function (el, binding) {
//     document.title = el.innerText
//     el.remove()
//   }
// })

// 引入es6语法
// require('es6-promise').polyfill()
// Es6Promise.polyfill()

// eventBus 事件车
Vue.prototype.$bus = new Vue()

Vue.config.productionTip = false

// 使用百度地图
Vue.use(BaiduMap, {
  ak: '2hpHS0HQqEhJlxnWpFYfGivY9MlILDTi'
  //  在此输入你自己的百度地图ak
})
// 钩子函数


router.beforeEach((to, from, next) => {
  // 百度统计
  // 创建vuerouter后，调用beforeEach方法，每个路由跳转时都将其跳转的路由推给百度
  if (_hmt) {
    console.log(_hmt);
    if (to.path) {
      _hmt.push(['_trackPageview', '/' + to.fullPath]);
    }
  }
  next();
});

// 路由守卫
router.afterEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0

  setTimeout(() => {
    var _hmt = _hmt || [];
    (function () {
      //每次执行前，先移除上次插入的代码
      document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?7b64961c365f4085633e253765a666fb";
      hm.id = "baidu_tj"
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();

  }, 0);

})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


