<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getInterviewId, getVisitorId } from "@/api/behavior.js";
import { getIp } from "@/api/App.js";
// import { getmedia } from '@/api/advertising/advertising.js'

export default {
  created() {
    // const path = this.$route.path
    // console.log(path)

    // 拿到媒体
    // getmedia().then((data) => {
    //   // console.log(data)
    // })

    // 存储访客id
    if (!this.$Cookie.get("MJ0INTERVIEWID")) {
      getInterviewId().then((data) => {
        // console.log(data)
        this.$Cookie.set("MJ0INTERVIEWID", data.data, {
          expires: 180,
        });
      });
    }
    // 存储访次id
    if (!this.$Cookie.get("MJ0VISITORID")) {
      getVisitorId().then((data) => {
        this.$Cookie.set("MJ0VISITORID", data.data, {
          expires: 1,
        });
      });
    }

    // // 获取当前用户的ip并存储到cookie
    // // eslint-disable-next-line no-undef
    // var Ip = returnCitySN.cip;
    // this.$Cookie.set("ip", Ip, {
    //   expires: 9999,
    // });
    // // 获取当前用户的id并存储到cookie
    // // eslint-disable-next-line no-undef
    // var Id = returnCitySN.cid;
    // this.$Cookie.set("MJ0CITYCODE", Id, {
    //   expires: 9999,
    // });
    // // 获取当前用户的地址并存储到cookie
    // // eslint-disable-next-line no-undef
    // var cname = returnCitySN.cname;
    // this.$Cookie.set("Name", cname, {
    //   expires: 9999,
    // });
    // 存储会员
    this.$Cookie.set("isMember", 0, {
      expires: 9999,
    });

    // 获取操作系统
    // eslint-disable-next-line camelcase
    const user_agent = navigator.userAgent;
    var UA = require("ua-device");
    // eslint-disable-next-line camelcase
    var input = user_agent;
    var output = new UA(input);

    // 添加操作系统和版本号
    this.$Cookie.set(
      "operatingSystem",
      output.os.name + "/" + output.os.version.alias,
      {
        expires: 9999,
      }
    );
    // 添加浏览器类型
    this.$Cookie.set("browserType", output.browser.name, {
      expires: 9999,
    });
    // 添加浏览器版本
    this.$Cookie.set("browserVersion", output.browser.version.original, {
      expires: 9999,
    });
    // 浏览器支持的编码格式
    this.$Cookie.set("browserEncoding", document.charset, {
      expires: 9999,
    });
    // 浏览器使用的语言
    this.$Cookie.set(
      "browserLanguage",
      (navigator.language || navigator.browserLanguage).toLowerCase(),
      {
        expires: 9999,
      }
    );
    // 客户屏幕的分辨率
    this.$Cookie.set(
      "screenResolution",
      window.screen.width + "x" + window.screen.height,
      {
        expires: 9999,
      }
    );

    // 获取当前客户端的时间
    var date = new Date();
    var time =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    var time1 =
      date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds();
    this.$Cookie.set("clientTime", time + " " + time1, {
      expires: 9999,
    });
  },
  mounted() { },
  methods: {},
};
</script>
<style lang="less">
// @import "~commonCss";

body {
  margin: 0px;
}

* {
  margin: 0;
  padding: 0;
  border: none;
  vertical-align: baseline;
  line-height: 0;
  list-style: none;
  border-collapse: collapse;
  border-spacing: 0;
}
</style>