
import instance from '@/utils/request.js'

// 把app.js 里面的东西都提交给后台
export function postForm(title) {
  return instance({
    url: '/portal/site/visit/log',
    method: 'post',
    params: { pageTitle: title }
  })
}

