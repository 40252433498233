<template>
  <div class="main">
    <div class="error">
      <div class="pic">
        <img
          src="http://mjgw.oss-cn-shanghai.aliyuncs.com/upload/2022-09-08/08f05f52fb35a56772fa44a14fcf0bea.png"
          alt=""
        />
      </div>
      <div class="span1">抱歉！您访问的页面可能不存在。</div>
      <div class="span2">无法找到您要访问的页面。 以下链接可能有帮助。</div>
      <button class="btn" @click="toLink()">返回首页</button>
      <div class="pic1" @click="toAdvict()">
        <img :src="data.imgPathPc" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { getPopularActivities } from "@/api/views/name.js";
export default {
  data() {
    return {
      data: {},
    };
  },
  mounted() {
    getPopularActivities().then((data) => {
      console.log(data);
      this.data = data.data;
    });
  },
  methods: {
    toLink() {
      this.$router.push("/");
    },
    toAdvict() {
      if (!this.data.url.match(/(http|https):\/\/\S*/)) {
        console.log("不携带http");
        const { href } = this.$router.resolve({ path: this.data.url });
        window.open(href, this.data.target);
      } else {
        console.log("携带http");
        window.open(this.data.url, this.data.target);
      }
    },
  },
};
</script>
<style lang="less" scoped>

.main {
  position: relative;
  margin-top: 80px;
}

.error {
  width: 1000px;
  height: 721px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.pic {
  width: 500px;
  height: 285px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin: 0 auto;
}

.span1 {
  width: 240px;
  height: 24px;
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #211917;
  line-height: 24px;
  margin: 0 auto;
  margin-top: 32px;
}

.span2 {
  width: 168px;
  height: 48px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #474646;
  line-height: 24px;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
}

.btn {
  width: 136px;
  height: 40px;
  background: #fa4416;
  border-radius: 100px 100px 100px 100px;
  opacity: 1;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffffcb;
  cursor: pointer;
  margin-top: 32px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.pic1 {
  width: 1000px;
  height: 160px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin-top: 155px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>