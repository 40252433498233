import axios from 'axios'
axios.defaults.headers['content-type'] = 'application/json'
axios.defaults.headers['TENANT-ID'] = 1 // 租户ID
axios.defaults.headers['Authorization'] = 'Basic cWptLXBvcnRhbDpxaWFuamlhbWVpLXBvcnRhbC1zZXJ2ZXI='// token // 租户ID
axios.defaults.headers['VERSION'] = 'rebuild'

// 创建一个axios实例对象
const instance = axios.create({
  baseURL: '/'
  // timeout: 6000
})


// HTTPrequest拦截
axios.interceptors.request.use(config => {

  config.headers['Authorization'] = 'Basic cWptLXBvcnRhbDpxaWFuamlhbWVpLXBvcnRhbC1zZXJ2ZXI='// token

  // headers中配置serialize为true开启序列化
  if (config.method === 'post' && config.headers.serialize) {
    config.data = serialize(config.data)
    delete config.data.serialize
  }

  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }

  return config
}, error => {
  return Promise.reject(error)
})


// 添加响应拦截器
instance.interceptors.response.use(function (data) {
  // 对响应数据做点什么
  return data.data
}, function (error) {
  // 对响应错误做点什么
  // return Promise.reject(error)
})
export default instance
