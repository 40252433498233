import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from "../views/404";

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

//
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/Home.vue'),
    meta: {
      title: '沪尚茗居',
      content: {
        keywords: '这里是第一个keywords',
        description: '这里是第一个description'
      }
    }
  },
  // 软装搭配
  {
    path: '/finish/solution',
    component: () => import('../views/Solution.vue'),
    meta: {
      content: {
        keywords: '',
        description: ''
      }
    }
  },
  // 工艺建材
  {
    path: '/strict',
    component: () => import('../views/Strict.vue'),
    meta: {
      content: {
        keywords: '',
        description: ''
      }
    }
  },
  // 茗匠工艺
  {
    path: '/framesite',
    component: () => import('../views/framesite.vue'),
    meta: {
      content: {
        keywords: '',
        description: ''
      }
    }
  },
  // 设计团队
  {
    path: '/recruitment/index',
    component: () => import('../views/Recruitment.vue'),
    meta: {
      content: {
        keywords: '',
        description: ''
      }
    }
  },
  // 实景样板间
  {
    path: '/product/index',
    component: () => import('../views/Product.vue'),
    meta: {
      content: {
        keywords: '',
        description: ''
      }
    }
  },
  // 装修案例
  {
    path: '/investor/index',
    component: () => import('../views/Investor.vue'),
    meta: {

      content: {
        keywords: '',
        description: ''
      }
    }
  },
  // 关于我们
  {
    path: '/about',
    component: () => import('../views/About.vue'),
    meta: {
      content: {
        keywords: '',
        description: ''
      }
    }
  },
  // 局改换新
  {
    path: '/finish/decoration',
    component: () => import('../views/Decoration.vue'),
    meta: {
      content: {
        keywords: '',
        description: ''
      }
    }
  },
  // 个性化定制
  {
    path: '/finish/bardian',
    component: () => import('../views/Bardian.vue'),
    meta: {
      content: {
        keywords: '',
        description: ''
      }
    }
  },
  {
    path: '/entirety',
    component: () => import('../views/Entirety.vue')
  },
  // 装修产品
  {
    path: '/finish',
    component: () => import('../views/Finish.vue'),
    meta: {
      content: {
        keywords: '',
        description: ''
      }
    }
  },
  // 带有360全景观看的案例详情页
  {
    path: '/product/detail',
    component: () => import('../views/Stylist.vue')
  },
  // 没有360的详情页
  // detail
  {
    path: '/investor/detail',
    component: () => import('../views/Stylist-no.vue')
  },
  // 设置详情页
  {
    path: '/stylist-div',
    component: () => import('../views/Stylist-div.vue')
  },
  // 设计师列表点进去对应id的单独设计师的详情页
  {
    path: '/recruitment/architect',
    component: () => import('../views/Architect.vue')
  },
  // 企业蓝图
  {
    path: '/about/blueprint',
    component: () => import('../views/Blueprint.vue'),
    meta: {
      content: {
        keywords: '',
        description: ''
      }
    }
  },
  // 新闻动态
  {
    path: '/about/dynamic',
    component: () => import('../views/Dynamic.vue'),
    meta: {
      content: {
        keywords: '',
        description: ''
      }
    }
  },
  // 新闻动态里面的详情页
  {
    path: '/about/dynamic/practical',
    component: () => import('../views/Practical.vue')
  },
  // pagePC页面
  {
    path: '/view',
    component: () => import('../views/view.vue')
  },
  //LP页面
  {
    path: '/pages/active/index',
    component: () => import('../views/active/index.vue')
  },
  {
    path: '/pages/active/show',
    component: () => import('../views/active/show.vue')
  },
  // page移动端页面
  {
    path: '/m/view',
    component: () => import('../views/wap.vue')
  },
  // Home轮播广告落地页1
  {
    path: '/view/one',
    component: () => import('../views/PcOneadvert.vue')
  },
  // Home轮播广告落地页2
  {
    path: '/view/two',
    component: () => import('../views/PcTwoadvert.vue')
  },
  // Home轮播广告落地页3
  {
    path: '/view/three',
    component: () => import('../views/PcThreeadvert.vue')
  },
  // 品牌概况
  {
    path: '/about/group',
    component: () => import('../views/group.vue'),
    meta: {

      content: {
        keywords: '',
        description: ''
      }
    }
  },
  // 向往的新家
  {
    path: '/yearn',
    component: () => import('../views/yearn.vue'),
    meta: {

      content: {
        keywords: '',
        description: ''
      }
    }
  },
  // 隐私政策
  {
    path: '/privacy',
    component: () => import('../views/privacy.vue'),
    meta: {

      content: {
        keywords: '',
        description: ''
      }
    }
  },
  // 测试
  {
    path: '/ceshi',
    component: () => import('../views/ceshi.vue'),
    meta: {
      content: {
        keywords: '',
        description: ''
      }
    }
  },
  // 大屏活动
  {
    path: '/big',
    component: () => import('../views/big.vue'),
    meta: {
      content: {
        keywords: '',
        description: ''
      }
    }
  },
  // 大屏活动
  {
    path: '/privacyScrm',
    component: () => import('../views/privacyScrm.vue'),
    meta: {
      content: {
        keywords: '',
        description: ''
      }
    }
  },
  {
    path: '*',
    name: '404',
    component: NotFound
  }
]

const router = new VueRouter({
  routes,
  // mode: 'hash'
  mode: 'history'
  // base: '/mj/'
})

export default router
